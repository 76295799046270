export const paths = [
  {
    d: "M6372.29 5609.3l1240.53 -1258.91c-423.86,-414.46 -986.54,-649.09 -1579,-658.33l0 1768.34c128.96,8.62 245.56,60.33 338.47,148.9z",
  },
  {
    d: "M6540.66 5988.44l1767.55 0c4.93,-569.26 -258.62,-1216.48 -660.34,-1603.05l-1241.31 1259.72c82.75,94.61 130.83,217.67 134.1,343.33z",
  },
  {
    d: "M6402.05 6365.71l1259.27 1240.87c404.38,-419.56 637.95,-986 646.63,-1568.61l-1768.25 0c-7.81,122.65 -56.01,236.7 -137.65,327.74z",
  },
  {
    d: "M6033.82 6545.34l0 1768.34c590.06,-9.22 1174.47,-255.86 1592.79,-671.78l-1259.25 -1240.87c-90.02,83.74 -211.02,136.12 -333.54,144.31z",
  },
  {
    d: "M5645.42 6417.49l-1241.43 1259.83c427.37,406.69 990.19,633.44 1580.31,636.66l0 -1767.54c-122.08,-2.85 -245.91,-50.01 -338.88,-128.95z",
  },
  {
    d: "M5454.56 6037.97l-1768.25 0c8.98,603.84 253.34,1178.92 682.21,1604.79l1240.63 -1259.01c-92.42,-94.09 -146.13,-212.98 -154.59,-345.78z",
  },
  {
    d: "M5593.55 5638.52l-1259.19 -1240.81c-413.82,428.59 -644.69,994.68 -648.31,1590.73l1767.55 0c3.4,-130.01 52.35,-252.94 139.95,-349.92z",
  },
  {
    d: "M5984.3 3691.76l0 1767.54c-143.97,3.33 -242.27,39.09 -355.92,144.03l-1259.29 -1240.9c430.96,-427.75 1007.58,-667.34 1615.21,-670.67z",
  },

  {
    d: "M4615.23 2641.34c420.14,-172.95 879.8,-269.39 1361.73,-272.03l0 1225.1c-311.03,2.56 -608,64.04 -880.3,173.87l-481.43 -1126.94z",
  },
  {
    d: "M3437.51 3423.75c316.8,-314.44 691.28,-570.73 1106.07,-752l481.38 1126.83c-267.28,118.07 -509.03,283.28 -714.73,485.14l-872.72 -859.97z",
  },
  {
    d: "M2645.94 4595.66c175.56,-417.63 427.02,-795.44 736.96,-1116.45l872.56 859.83c-199.06,208.33 -361.13,452.31 -475.64,721.41l-1133.88 -464.79z",
  },
  {
    d: "M2363.61 5980.18c2.83,-463.14 92.16,-905.78 252.88,-1312.44l1134.08 464.87c-102.05,263.27 -159.11,548.91 -161.87,847.57l-1225.09 0z",
  },
  {
    d: "M2648.14 7415.47c-176.68,-418.25 -276.96,-876.56 -284.12,-1357.42l1225.09 0c6.98,309.99 72.48,605.56 185.97,876l-1126.94 481.42z",
  },
  {
    d: "M3436.62 8581.12c-315.21,-313.01 -573.26,-683.46 -757.31,-1094.3l1127.1 -481.5c120.73,263.34 287.44,501.27 490.03,703.24l-859.82 872.56z",
  },
  {
    d: "M4604.36 9359.97c-415.1,-172.4 -791.39,-419.43 -1111.97,-724.51l860.16 -872.9c207.83,194.28 450.02,352.25 716.56,463.64l-464.75 1133.77z",
  },
  {
    d: "M5976.96 9636.43c-458.57,-2.5 -897,-89.89 -1300.41,-247.31l464.9 -1134.14c259.97,98.84 541.44,153.92 835.51,156.35l0 1225.1z",
  },
  {
    d: "M7399.13 9356.19c-414.66,173.61 -868.41,272.34 -1344.32,279.76l0 -1225.09c305.06,-7.17 596.04,-71.15 862.9,-181.61l481.42 1126.94z",
  },
  {
    d: "M8559.02 8579.78c-312.21,310.41 -680.59,564.34 -1088.43,745.51l-481.39 -1126.85c260.39,-117.85 496,-280.77 697.1,-478.64l872.72 859.98z",
  },
  {
    d: "M9342.16 7424.66c-174.84,410.86 -423.27,782.83 -728.57,1099.59l-872.57 -859.83c194.47,-204.05 353.37,-442.28 467.08,-704.63l1134.06 464.87z",
  },
  {
    d: "M9630.24 6058.05c-6.82,456.94 -97.87,893.42 -258.46,1294.62l-1133.77 -464.74c101.91,-257.8 160.56,-537.42 167.14,-829.88l1225.09 0z",
  },
  {
    d: "M9362.99 4631.57c169.95,416.53 264.73,871.67 267.66,1348.61l-1225.09 0c-2.84,-306.09 -62.62,-598.54 -169.51,-867.19l1126.94 -481.42z",
  },
  {
    d: "M8592.44 3459.84c309.67,315.93 561.97,688.21 740.41,1099.96l-1126.84 481.38c-115.25,-264.24 -276.4,-503.87 -473.54,-708.63l859.97 -872.71z",
  },
  {
    d: "M7433.22 2664.1c413.36,178.03 786.97,430.6 1104.14,740.72l-859.82 872.56c-204.55,-199.21 -444.34,-362.38 -709.19,-479.22l464.87 -1134.06z",
  },
  {
    d: "M6054.81 2369.79c461.52,7.2 902.12,100.39 1306.55,264.32l-464.74 1133.77c-261.08,-105.17 -544.77,-166 -841.81,-173l0 -1225.09z",
  },
  {
    d: "M4214.53 1666.6c541.97,-223.09 1134.92,-347.49 1756.57,-350.89l0 937.03c-490.95,3.35 -959.46,101 -1388.35,275.82l-368.22 -861.96z",
  },
  {
    d: "M2695.31 2675.88c408.65,-405.61 891.73,-736.21 1426.78,-970.05l368.19 861.87c-422.22,185.51 -803.75,446.47 -1127.46,765.95l-667.51 -657.77z",
  },
  {
    d: "M1674.21 4187.61c226.47,-538.73 550.84,-1026.09 950.64,-1440.17l667.39 657.65c-314.99,327.92 -571.05,712.88 -750.86,1137.99l-867.17 -355.47z",
  },
  {
    d: "M1310.01 5973.61c3.66,-597.45 118.88,-1168.44 326.21,-1693.02l867.37 355.55c-162.45,414.92 -252.93,865.83 -256.55,1337.47l-937.03 0z",
  },
  {
    d: "M1677.04 7825.07c-227.91,-539.53 -357.25,-1130.73 -366.5,-1751.03l937.03 0c9.12,489.62 111.9,956.32 291.43,1382.81l-861.96 368.22z",
  },
  {
    d: "M2694.16 9328.73c-406.61,-403.77 -739.49,-881.65 -976.92,-1411.62l861.93 -368.21c189.03,417.16 452.15,793.59 772.63,1112.44l-657.64 667.39z",
  },
  {
    d: "M4200.5 10333.41c-535.47,-222.39 -1020.86,-541.05 -1434.41,-934.59l657.91 -667.65c327.32,308.78 710.13,559.34 1131.97,735.07l-355.47 867.17z",
  },
  {
    d: "M5971.1 10690.03c-591.53,-3.22 -1157.09,-115.95 -1677.48,-319.01l355.58 -867.46c410.69,158.25 856.2,246.26 1321.9,249.44l0 937.03z",
  },
  {
    d: "M7805.66 10328.54c-534.9,223.96 -1120.22,351.31 -1734.13,360.88l0 -937.03c483.24,-9.42 944.04,-110.18 1365.91,-285.81l368.22 861.96z",
  },
  {
    d: "M9301.89 9326.99c-402.75,400.43 -877.94,727.98 -1404.05,961.69l-368.19 -861.87c413.33,-185.3 786.96,-443.25 1104.73,-757.58l667.51 657.76z",
  },
  {
    d: "M10312.1 7836.94c-225.53,530 -546,1009.81 -939.82,1418.43l-667.39 -657.65c309.05,-322.42 561,-699.95 739.81,-1116.34l867.4 355.56z",
  },
  {
    d: "M10683.72 6074.04c-8.79,589.44 -126.25,1152.5 -333.41,1670.03l-867.17 -355.46c162.27,-407.86 254.9,-850.94 263.55,-1314.57l937.03 0z",
  },
  {
    d: "M10338.98 4233.94c219.22,537.31 341.49,1124.42 345.27,1739.67l-937.03 0c-3.72,-484.56 -99.23,-947.24 -270.19,-1371.45l861.95 -368.22z",
  },
  {
    d: "M9345 2722.44c399.46,407.55 724.92,887.77 955.09,1418.91l-861.88 368.19c-181.84,-418.32 -437.61,-797.05 -750.97,-1119.59l657.76 -667.51z",
  },
  {
    d: "M7849.63 1695.97c533.22,229.66 1015.17,555.46 1424.31,955.5l-657.64 667.39c-323.01,-315.2 -702.62,-572.6 -1122.23,-755.49l355.56 -867.4z",
  },
  {
    d: "M6071.53 1316.32c595.35,9.29 1163.71,129.49 1685.41,340.97l-355.46 867.17c-412.05,-166.53 -860.41,-261.95 -1329.95,-271.11l0 -937.03z",
  },
  {
    d: "M4542.61 378.26c449.09,-115.87 919.39,-178.86 1403.72,-183.01l0 1017.7c-393.22,4.1 -775.18,55.24 -1140.23,148.67l-263.49 -983.36z",
  },
  {
    d: "M3136.74 946.95c407.72,-231.24 846.25,-414.36 1307.79,-542.15l263.45 983.2c-374.6,104.49 -730.72,253.28 -1062.28,440.5l-508.96 -881.55z",
  },
  {
    d: "M1926.41 1860.31c336.63,-330.89 713.5,-620.9 1122.45,-862.39l508.93 881.49c-331.77,196.75 -637.81,432.29 -911.75,700.53l-719.63 -719.63z",
  },
  {
    d: "M992.17 3054.61c241.42,-409.01 531.57,-785.76 862.4,-1122.46l719.63 719.63c-268.23,273.97 -503.83,579.95 -700.54,911.76l-881.49 -508.93z",
  },
  {
    d: "M399.06 4450.28c127.72,-461.58 310.99,-900.01 542.15,-1307.79l881.54 508.96c-187.19,331.6 -336.03,687.67 -440.49,1062.28l-983.2 -263.45z",
  },
  {
    d: "M189.51 5952.07c4.16,-484.32 67.22,-954.58 183.01,-1403.71l983.35 263.49c-93.41,365.07 -144.57,747 -148.66,1140.22l-1017.7 0z",
  },
  {
    d: "M372.52 7457.38c-115.87,-449.08 -178.86,-919.39 -183.01,-1403.71l1017.7 0c4.1,393.22 55.24,775.17 148.66,1140.22l-983.35 263.49z",
  },
  {
    d: "M941.2 8863.25c-231.23,-407.73 -414.35,-846.25 -542.14,-1307.79l983.2 -263.45c104.49,374.6 253.28,730.72 440.49,1062.28l-881.55 508.96z",
  },
  {
    d: "M1854.56 10073.59c-330.89,-336.64 -620.91,-713.5 -862.39,-1122.46l881.49 -508.93c196.75,331.78 432.3,637.8 700.54,911.75l-719.64 719.64z",
  },
  {
    d: "M3048.86 11007.82c-409,-241.42 -785.77,-531.55 -1122.46,-862.39l719.64 -719.63c273.97,268.22 579.95,503.82 911.75,700.53l-508.93 881.49z",
  },
  {
    d: "M4444.53 11600.94c-461.57,-127.72 -900.01,-310.98 -1307.79,-542.15l508.96 -881.55c331.6,187.19 687.67,336.04 1062.28,440.5l-263.45 983.2z",
  },
  {
    d: "M5946.33 11810.49c-484.32,-4.16 -954.58,-67.22 -1403.72,-183.01l263.49 -983.36c365.08,93.42 747.01,144.58 1140.23,148.67l0 1017.7z",
  },
  {
    d: "M7939.79 10381.52c518.38,-229.99 996.27,-552.13 1408.43,-955.72l719.63 719.63c-505.44,496.8 -1087.92,890.23 -1718.86,1169.66l-409.2 -933.57z",
  },
  {
    d: "M6047.93 11810.49l0 -1017.7c622.57,-6.5 1231.48,-134.26 1795.24,-369.71l394.84 939.71c-687.48,287.41 -1429.5,441.18 -2190.08,447.7z",
  },
  {
    d: "M11002.09 8951.13c-241.43,409 -531.56,785.77 -862.4,1122.46l-719.63 -719.63c268.23,-273.97 503.83,-579.95 700.54,-911.76l881.49 508.93z",
  },
  {
    d: "M11595.2 7555.46c-127.74,461.57 -310.98,900.02 -542.15,1307.79l-881.54 -508.96c187.19,-331.6 336.03,-687.67 440.49,-1062.28l983.2 263.45z",
  },
  {
    d: "M11804.75 6053.67c-4.16,484.31 -67.2,954.58 -183.01,1403.71l-983.35 -263.49c93.41,-365.07 144.57,-747 148.66,-1140.22l1017.7 0z",
  },
  {
    d: "M11621.74 4548.36c115.87,449.08 178.86,919.39 183.01,1403.71l-1017.7 0c-4.1,-393.22 -55.24,-775.17 -148.66,-1140.22l983.35 -263.49z",
  },
  {
    d: "M11053.06 3142.49c231.23,407.73 414.35,846.25 542.14,1307.79l-983.2 263.45c-104.49,-374.6 -253.28,-730.72 -440.49,-1062.28l881.55 -508.96z",
  },
  {
    d: "M10139.7 1932.15c330.89,336.64 620.91,713.5 862.39,1122.46l-881.49 508.93c-196.75,-331.78 -432.3,-637.8 -700.54,-911.75l719.64 -719.64z",
  },
  {
    d: "M8945.4 997.92c409,241.42 785.77,531.55 1122.46,862.39l-719.64 719.63c-273.97,-268.22 -579.95,-503.82 -911.75,-700.53l508.93 -881.49z",
  },
  {
    d: "M7549.73 404.8c461.57,127.72 900.01,310.98 1307.79,542.15l-508.96 881.55c-331.6,-187.19 -687.67,-336.04 -1062.28,-440.5l263.45 -983.2z",
  },
  {
    d: "M6047.93 195.25c484.32,4.16 954.58,67.22 1403.72,183.01l-263.49 983.36c-365.08,-93.42 -747.01,-144.58 -1140.23,-148.67l0 -1017.7z",
  },
];
