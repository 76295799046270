export const pathsRight = [
  {
    d: "M3984.88 3103.2l964.83 750.83 -2720.81 0c-1.34,-206.43 -10.58,-410.85 -27.21,-613.26l1783.19 -137.57z",
  },
  {
    d: "M2764.23 2153.15l1081.24 841.37 -1654.02 127.49c-27.05,-280.52 -68.36,-556.83 -122.79,-828.27l695.57 -140.59z",
  },
  {
    d: "M1176.56 3854.03l993.05 0c-3.87,-519.54 -56.94,-1039.41 -159.58,-1548.7l-973.56 196.52c89.7,444.29 136.39,898.65 140.09,1352.17z",
  },
  {
    d: "M9.99 9.99c884.21,688.18 1749.76,1361.58 2630.27,2046.74 -542.72,109.68 -1085.6,219.04 -1628.32,328.72 -188.63,-860.52 -532.97,-1662.75 -1001.95,-2375.46z",
  },
  {
    d: "M4945.34 3973.12l-4698.68 3458.88c582.36,-1021.44 919.65,-2201.27 929.89,-3458.88l3768.79 0z",
  },
];
