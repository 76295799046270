import React, { useState } from 'react';
import axios from 'axios';
import { Card, Form, Button } from 'react-bootstrap';
import Logo from "./assets/logo.png"
import "./Login.css";
import { URL } from '../config.js';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      //const url = `${URL}/nature-garden-api/login`;
      const url = `${URL}/nature-garden-api/users/login`;

      const response = await axios.post(url, { username, password });

      // Check for a successful login response
      if (response.status === 200 && response.data.message) {
        setError('');
        
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("role", response.data.role);
        localStorage.setItem("token", response.data.token);
      
        onLogin(true);
        
      } else {
        setError('Invalid username or password');
      }
    } catch (error) {
      setError('Invalid username or password');
    }
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-center login-page">
        <div className='login-card-wrapper'>
          <img src={Logo} alt="Logo" width={140} style={{ marginBottom: "2rem" }} />
          <div style={{ background: "none", borderRadius: "0rem 0rem 0.9rem 0.9rem" }}>
            <Form className='login-form-wrapper' onSubmit={handleLogin}>
              <Form.Group className='login-form-item-wrapper' controlId="username">
                <Form.Control
                  className='input-item-wrapper'
                  type="text"
                  placeholder="Utilizador"
                  value={username}
                  onChange={(event) => { setUsername(event.target.value); setError(''); }}
                  style={{ borderRadius: "1.5rem", fontSize: "small" }}
                  required
                />
              </Form.Group>
              <Form.Group className='login-form-item-wrapper' controlId="password">
                <Form.Control
                  className='input-item-wrapper'
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => { setPassword(event.target.value); setError(''); }}
                  style={{ borderRadius: "1.5rem", fontSize: "small" }}
                  required
                />
              </Form.Group>
              <Form.Group style={{ height: "1.5rem", display: "block", marginTop: "1rem" }}>
                <div className='error-wrapper' style={{ display: error ? "" : "none" }}>
                  {error}
                </div>
              </Form.Group>
              <div className="col-12">
                <button type="submit" className="col-6 login-button-btn" style={{ borderRadius: "1.5rem" }}>
                  Login
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div className="footer-developer">
          &copy;2023 - <span style={{ textDecoration: "none", color: "gray" }}>developed by </span><b>TECH</b> @ <a href='http://www.morecolab.pt' style={{ textDecoration: "none", color: "gray" }}>MORECOLAB.PT</a>
        </div>
      </div>
    </>
  );
};

export default Login;
