import React, { useEffect, useRef, useState } from "react";
import Clock from "../assets/clock.svg";
import { AiOutlineClockCircle } from "react-icons/ai";
import "./Administration.css";
import { URL } from '../../config';

const Administration = () => {
  const [startTime, setStartTime] = useState("");
  const [duration, setDuration] = useState("");
  const [notification, setNotification] = useState("");
  const [isLoading, setIsloading] = useState(true);
  const [deviceIds, setDeviceIds] = useState([{}]);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
   

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  }

  useEffect(() => {
    if (isLoading) {
      fetchDeviceIds();
    }
  }, []);

  const fetchDeviceIds = async () => {
    try {
      const response = await fetch(`${URL}/api-nature-garden/devices`);
      const data = await response.json();
      setDeviceIds(data);
    } catch (error) {
      console.error("Error fetching device IDs:", error);
    }
  };

  const handleSetSchedule = () => {
    const payload = {
      startTime: startTime,
      durationMinutes: parseInt(duration),
      deviceId: selectedDeviceId
    };

    fetch(`${URL}/api-nature-garden/setWateringSchedule`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle successful response
        console.log(data);
        setNotification("Horário de rega definido com sucesso!"); // Set notification message (Portuguese translation)
        setStartTime(""); // Clear the inputs
        setDuration("");
      })
      .catch((error) => {
        // Handle error response
        console.error("Error setting watering schedule:", error);
        setNotification("Falha ao definir o a rega. Por favor, tente novamente."); // Set error notification message (Portuguese translation)
      });
  };

  return (
    <div className="settings-container">
      <div className="settings-wrapper">
        <div className="col-xl-3 col-md-6 col-sm-12 card card-schedule">
          <div>
            <div className="col-12 mb-4"><AiOutlineClockCircle size={100} color="#59817f" /></div>
            <div className="input-group">
            <label>Selecionar dispositivo:</label>
              <select
                className="input-item"
                value={selectedDeviceId}
                onChange={handleDeviceChange}
              >
                <option value="" style={{ textAlign: "left" }}>
                  Selecionar dispositivo...
                </option>
                {deviceIds?.map(({ id, device_id }) => (
                  <option
                    style={{ textAlign: "left" }}
                    key={id}
                    value={device_id}
                    //disabled={usedDeviceIds.includes(id)}
                  >
                    {device_id}
                  </option>
                ))}
              </select>

              <label>Abrir válvula: </label>
              <input
                className="input-item"
                type="datetime-local"
                value={startTime}
                onChange={handleStartTimeChange}
              />

              <label>Duração (min):</label>
              <input
                className="input-item"
                type="number"
                value={duration}
                onChange={handleDurationChange}
              />
            </div>
          </div>

          <button className="button" onClick={handleSetSchedule}>
            Definir
          </button>
          
          {notification && (
            <div className="notification">
              {notification}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Administration;
