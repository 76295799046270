import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import "./Navbar.css"
import logo2 from "../assets/logo2.png"; 
import { Link } from 'react-router-dom';
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import {MdOutlineLogout, MdAdminPanelSettings } from "react-icons/md";

function NavbarComponent() {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const perfil = localStorage.getItem('role');

  return (
    <Navbar bg="white" className='navbar-container'>
      <Container className="col-12" style={{ marginLeft: "5px" }}>
        <Link to="/">
          <img
            src={logo2}
            alt="Logo"
            style={{ width: 200 }}
          />
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" style={{ textDecoration: 'none', background:"none" }}>
          <Nav className="ml-auto" style={{ padding: 0 }}>
            <Dropdown alignRight>
              <Dropdown.Toggle
                variant="link"
                id="dropdown-basic"
                style={{ textDecoration: 'none',  outline: 'none' }}
              >
                {<span style={{
                  fontSize: 10,
                  marginRight: 5
                }}>{perfil}</span>}
                <AiOutlineUser size={25} color='#59817f' style={{ color: '#59817f' }} />
                {/* Apply the color style to change the color of the arrow down icon */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* Add your dropdown menu items here */}
               
                <Dropdown.Divider />
                <Dropdown.Item as={Link} to="/devices">Dispositivos</Dropdown.Item>
                <Dropdown.Item as={Link} to="/hortas">Hortas</Dropdown.Item>
                <Dropdown.Item as={Link} to="/schedule">Agendamento</Dropdown.Item>
                <Dropdown.Item as={Link} to="/users">Utilizadores</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={handleLogout} style={{ display:"flex", flexDirection:"row", justifyContent:"space-between"}}><div>Sair</div><div><MdOutlineLogout size={22} /></div></Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavbarComponent;
