import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "./Dashboard.css";
import {
  AiOutlineSetting,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlinePoweroff,
} from "react-icons/ai";
import { RxColorWheel } from "react-icons/rx";
import PathComponent from "./PathComponent";
import Left from "../assets/left_map.png";
import Right from "../assets/right_map.png";
import Center from "../assets/center_map.png";
import rega from "../assets/rega.png";
import humidade from "../assets/humidade.png";
import horta2 from "../assets/hortaaaaaaa_print.png";
import temperatura from "../assets/temperatura.png";
import Modal from "react-modal";
import { paths } from "./svgPaths.js";
import { pathsLeft } from "./svgPathsLeft";
import { pathsRight } from "./svgPathsRight";
import { pathsrectangle } from "./svgrectangle";
import { URL } from "../../config.js";




const MapaHortasAdmin = () => {
  const [isLoading, setIsloading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedPathIndex, setSelectedPathIndex] = useState(null);
  const [hoveredPath, setHoveredPath] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [horta, setHorta] = useState("");
  const [deviceIds, setDeviceIds] = useState([{}]);
  const [sectionValues, setSectionValues] = useState([]);
  const [conversionTable, setConversionTable] = useState({});
  const [usedDeviceIds, setUsedDeviceIds] = useState([]);
  const [deviceSectionsMap, setDeviceSectionsMap] = useState([]);
  const [selectedMap, setSelectedMap] = useState("C"); // 'C' is the default map
  const [selectedGarden, setSelectedGarden] = useState("F"); // 'C' is the default map
  const polygonStyles = {
    fill: '#343a40', // Apply the desired fill color here
    stroke: 'none', // Remove stroke
  };

  const handleMapChange = (map) => {
    setSelectedMap(map);
  };

  const handleGardenChange = (map) => {
    setSelectedGarden(map);
  };



  const handleSimulatedMouseEnter = (index) => {
    setHoveredPath(index);
  };

  const fetchUsedDeviceIds = async () => {
    try {
      const response = await fetch(`${URL}/api-nature-garden/used-devices`);
      const data = await response.json();
      console.log(data);
      setUsedDeviceIds(data);
    } catch (error) {
      console.error("Error fetching used devices:", error);
    }
  };

  const fetchUpdatedSectionValue = async (sectionIndex) => {
    try {
      const response = await fetch(
        `${URL}/api-nature-garden/sections/${sectionIndex}`
      );
      const data = await response.json();

      // Update the section value in the conversion table
      setConversionTable((prevConversionTable) => ({
        ...prevConversionTable,
        [sectionIndex.toString()]: data.value,
      }));
    } catch (error) {
      console.error("Error fetching updated section value:", error);
    }
  };

  const fetchSectionValues = async () => {
    try {
      const response = await fetch(`${URL}/api-nature-garden/sections`);
      const data = await response.json();

      // Create a conversion table (object) to map section indices to their values
      const conversionTable = {};
      data.forEach((section) => {
        conversionTable[section.section_index.toString()] = section.value;
      });
      const deviceSectionsMap = {};
      data.forEach((section) => {
        if (!deviceSectionsMap[section.device_id]) {
          deviceSectionsMap[section.device_id] = [];
        }
        deviceSectionsMap[section.device_id].push(section.value);
      });

      // Set the section values and the conversion table states
      setSectionValues(data);
      setDeviceSectionsMap(deviceSectionsMap);
      setConversionTable(conversionTable);
      console.log(deviceSectionsMap);
      setIsloading(false);
    } catch (error) {
      console.error("Error fetching section values:", error);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchSectionValues();
      fetchDeviceIds();
      fetchUsedDeviceIds();
    }
  }, [conversionTable]);

  const fetchDeviceIds = async () => {
    try {
      const response = await fetch(`${URL}/api-nature-garden/devices`);
      const data = await response.json();
      setDeviceIds(data);
    } catch (error) {
      console.error("Error fetching device IDs:", error);
    }
  };

  const getSectionsForDevice = (device_id) => {
    const sections = deviceSectionsMap[device_id] || [];
    return sections.length > 0 ? sections.join(", ") : "None";
  };

  const handlePathHover = (index) => {
    setHoveredPath(index);
  };

  const handlePathLeave = () => {
    setHoveredPath(null);
  };

  const handleOpenModal = (index) => {
    // Clear input values here
    setHorta(""); // Assuming horta is the state for one of your input fields
    setSelectedDeviceId(""); // Assuming selectedDeviceId is the state for your device selection

    setShowModal(true);
    setSelectedPathIndex(index);
    setHorta(conversionTable[index] || "");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHorta(null);
    setDeviceIds(null);
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  const handleHortaChange = (event) => {
    setHorta(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${URL}/api-nature-garden/sections`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          sectionIndex: selectedPathIndex,
          value: horta,
          deviceId: selectedDeviceId,
        }),
      });

      if (response.ok) {
        // Section data submitted successfully, perform any necessary actions
        console.log("Section data submitted successfully");
        fetchUsedDeviceIds();
        setShowModal(false);
      } else {
        console.error("Error submitting section data");
      }
    } catch (error) {
      console.error("Error submitting section data:", error);
    }
  };

  const handleSubmitHorta = async () => {
    try {
      const response = await fetch(
        `${URL}/api-nature-garden/sections/${selectedPathIndex}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            value: horta,
          }),
        }
      );

      if (response.ok) {
        // Update the section value in the conversionTable
        setConversionTable((prevConversionTable) => ({
          ...prevConversionTable,
          [selectedPathIndex.toString()]: horta,
        }));
        fetchSectionValues();

        // Close the modal
        setShowModal(false);

        console.log("Section data submitted successfully");
      } else {
        console.error("Error submitting section data");
      }
    } catch (error) {
      console.error("Error submitting section data:", error);
    }
  };
  const rotationAngle = 40; // Rotation angle in degrees
  const leftMargin = 5000; // Left margin in pixels
  return (
    <div className="row" style={{ marginTop: "1.5%" }}>
      <div
        className="col-xl-3 col-md-10 col-sm-12 card card-schedule"
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          marginBottom: "5rem",
          paddingBottom: "0.9rem"
        }}
      >
        <div>
          <div
            className="col-12"
            style={{ textAlign: "center", marginTop: "-3rem" }}
          >
            <AiOutlineClockCircle size={100} color="#59817f" />
          </div>
          <div className="input-group">
            <label>Abrir válvula: </label>
            <input
              className="input-item"
              type="datetime-local"
              // value={startTime}
              // onChange={handleStartTimeChange}
            />

            <label>Duração (min):</label>
            <input
              className="input-item"
              type="number"
              //  value={duration}
              //  onChange={handleDurationChange}
            />
          </div>
        </div>

        <button className="button" /*onClick={handleSetSchedule}*/>
          Definir
        </button>
        <br />
        <h4 style={{ marginTop: "1rem", color: "#587c75" }}>
          Informações Gerais
        </h4>
        <div class="row" style={{ marginTop: "3rem"}}>
          <div className="col-xl-2 col-md-2 col-sm-2">
            <img src={rega} alt="rega" style={{ width: 25 }} />
          </div>
          <div className="col-xl-10 col-md-10 col-sm-10">
            <h6 style={{ color: "#587c75" }}>Rega</h6>
            <h6 style={{ color: "#587c75" }}>04:30h | 17:30h</h6>
          </div>
        </div>
        <div class="row" style={{ marginTop: "1rem" }}>
          <div className="col-xl-2 col-md-2 col-sm-2">
            <img src={humidade} alt="humidade" style={{ width: 25 }} />
          </div>
          <div className="col-xl-10 col-md-10 col-sm-10">
            <h6 style={{ color: "#587c75" }}>Humidade do ar</h6>
            <h6 style={{ color: "#587c75" }}>20%</h6>
          </div>
          </div>
          <div class="row">
            <div className="col-xl-2 col-md-2 col-sm-2">
              <img src={temperatura} alt="temperatura" style={{ width: 25 }} />
            </div>
            
            <div
              style={{ marginLeft: "0rem" }}
              className="col-xl-10 col-md-10 col-sm-10"
            >
              <h6 style={{ color: "#587c75" }}>
                Temperatura
              </h6>
              <h6 style={{ color: "#587c75"}}>10°C</h6>
            </div>
            </div>
      </div>
      <div
        className="col-xl-6 col-sm-12 "
        style={{
          textAlign: "center",
          justifyContent: "center",
          display: "flex",
          padding: "0",
          margin: "0"
        }}
      >
        {selectedGarden === "F" ?
        <svg
        className="center-map-container"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          width="75%"
          height="90%"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
          }}
          viewBox="0 0 12000 12000"
        >
        

          <circle
            cx="6000"
            cy="6000"
            r="5950"
            fill="#7FA3A1"
            filter="url(#shadow)" // Apply the shadow filter to the circle
          />

          {selectedMap === "L" && (
            <g
              id="Layer_x0020_L"
              transform={`translate(400, 400) scale(1.1, 1.1)`}
            >
              {pathsLeft.map((path, index) => (
                <PathComponent
                  key={index + 65}
                  path={path}
                  index={index + 65}
                  hoveredPath={hoveredPath}
                  handlePathHover={handlePathHover}
                  handlePathLeave={handlePathLeave}
                  handleOpenModal={handleOpenModal}
                  sectionValues={sectionValues}
                />
              ))}
            </g>
          )}

          {selectedMap === "C" && (
            <g id="Layer_x0020_C">
              {paths.map((path, index) => (
                <PathComponent
                  key={index + 1}
                  path={path}
                  index={index + 1}
                  hoveredPath={hoveredPath}
                  handlePathHover={handlePathHover}
                  handlePathLeave={handlePathLeave}
                  handleOpenModal={handleOpenModal}
                  sectionValues={sectionValues}
                />
              ))}
            </g>
          )}

          {selectedMap === "R" && (
            <g
              id="Layer_x0020_R"
              transform="translate(3800, 905) scale(1.4, 1.4)"
            >
              {pathsRight.map((path, index) => (
                <PathComponent
                  key={index + 80}
                  path={path}
                  index={index + 80}
                  hoveredPath={hoveredPath}
                  handlePathHover={handlePathHover}
                  handlePathLeave={handlePathLeave}
                  handleOpenModal={handleOpenModal}
                  sectionValues={sectionValues}
                />
              ))}
            </g>
          )}
        </svg>


        : 
        <div >
       {/*
        <svg
          className="center-map-container"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          width="100%"
          height="100%"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
            filter: "drop-shadow(10px 10px 10px rgba(0,0,0,0.5))",
            marginLeft: "25%",
            zIndex: -1,
          }}
          viewBox={`0 0 18500 13000`}
        >
          <path
            style={{
              fill: "#7da4a3",
              stroke: "#7da4a3",
              strokeWidth: 20,
              strokeLinecap: "round",
              strokeLinejoin: "round",
              strokeMiterlimit: 22.9256,
              opacity: 5.5, // Reduza a opacidade da sombra para torná-la menos intensa
            }}
            d="m9586.45 2828.06 1423.13 668.48 3113.48 747.79 1591.88 501.4 2577.63 804.75-8853.03 7138.71L207.3 4999.73 5153.12 316.54z"
          />
          </svg>*/}
      
        <svg
          className="center-map-container"
          xmlns="http://www.w3.org/2000/svg"
          xmlSpace="preserve"
          width="100%"
          height="90%"
          style={{
            shapeRendering: "geometricPrecision",
            textRendering: "geometricPrecision",
            imageRendering: "optimizeQuality",
            fillRule: "evenodd",
            clipRule: "evenodd",
           // marginLeft: "15%",
           // zIndex: 1,
          }}
          viewBox={`0 0 12000 12000`}
        >
          <circle
            cx="6000"
            cy="6000"
            r="5950"
            fill="#7da4a3"
            filter="url(#shadow)"
          />
          <g
            id="Layer_x0020_1"
            //style={{ border: "100px solid #7da4a3" }}
            transform={`translate(${leftMargin}, 0) scale(0.65, 0.65) rotate(${rotationAngle})`}
          >
            {pathsrectangle.map((pathData, index) => (
              <PathComponent
                key={index + 100}
                path={pathData}
                index={index + 100}
                hoveredPath={hoveredPath}
                handlePathHover={handlePathHover}
                handlePathLeave={handlePathLeave}
                handleOpenModal={handleOpenModal}
                sectionValues={sectionValues}
              />
            ))}
          </g>
        </svg>
      </div>
      

      }
      </div>
     

      {/* MENU SUPERIOR QUE VAI MUDAR O MAPA*/}
      <div
        className="col-xl-2"
       
      >
        <div className="col-12 card right-map-container">
           <select className="col-12 card right-map-container form-control-lg"
        value={selectedGarden}
        onChange={(e) => handleGardenChange(e.target.value)}
      >
        <option value="F">Horta Mandala</option>
        <option value="S">Horta hc_lotes</option>
      </select>
      <br/>
      
      {selectedGarden === "F" ?
          <><div className="col-12 right-map-container-wrapper">
              <button
                className={`button-map-ref mr-1 ${selectedMap === "L" ? "active" : ""}`}
                onClick={() => handleMapChange("L")}
              >
                <AiFillCaretLeft
                  color="white"
                  size={45}
                  style={{ marginLeft: "-0.4rem" }} />
              </button>
              <button
                className={`button-map-ref mr-1 ${selectedMap === "C" ? "active" : ""}`}
                onClick={() => handleMapChange("C")}
              >
                <RxColorWheel
                  color="white"
                  size={45}
                  style={{ marginRight: "-0.1rem" }} />
              </button>
              <button
                className={`button-map-ref ${selectedMap === "R" ? "active" : ""}`}
                onClick={() => handleMapChange("R")}
              >
                <AiFillCaretRight
                  color="white"
                  size={45}
                  style={{ marginRight: "-0.4rem" }} />
              </button>
            </div><h5 style={{ margin: "1.5rem 0rem" }}>
                {selectedMap === "L"
                  ? "zona esquerda"
                  : selectedMap === "C"
                    ? "zona central"
                    : "zona direita"}
              </h5><div className="col-12 mb-2">
                <img
                  src={selectedMap === "L"
                    ? Left
                    : selectedMap === "C"
                      ? Center
                      : Right}
                  width={"100%"}
                  alt={`mapa-${selectedMap.toLowerCase()}`} />
              </div></>

: <>       <div>
  <h5 style={{ margin: "1.5rem 0rem" }}>Horta Central</h5>
  <img
  src={horta2}
  alt="horta2"
  style={{ width:"100%" , backgroundColor:"white"}}
/>
</div>  
          
</> 
  }
        </div> 
        
        {/* <div className="col-12 mt-5">
          <div className="bottom-section">
            <AiOutlinePoweroff size={100} color="#59817f" />
            <h4>Válvula Geral</h4>
            <button className="button">ON</button>
          </div>
        </div> */}
      </div>
      
      {showModal && (
         <Modal
         isOpen={showModal}
         onRequestClose={() => setShowModal(false)}
         centered // Center the modal vertically
         className="custom-modal" // Apply custom styles using a class
       >
         <div
           style={{
             display: "flex",
             justifyContent: "flex-end",
             marginTop: "-5px",
           }}
         >
           <AiOutlineCloseCircle
             size={30}
             color="#587c75"
             onClick={() => setShowModal(false)}
             style={{ cursor: "pointer" }}
           />{" "}
         </div>
         <div class="row" style={{ marginTop: "5%" }}>
           <div className="col-12 mb-4" style={{ marginLeft: "7rem" }}>
             <AiOutlineClockCircle size={100} color="#59817f" />
           </div>
           <div className="input-group">
             <label>Abrir válvula :</label>
             <input
               className="input-item"
               type="datetime-local"
               // value={startTime}
               // onChange={handleStartTimeChange}
             />

             <label>Duração (min):</label>
             <input
               className="input-item"
               type="number"
               //  value={duration}
               //  onChange={handleDurationChange}
             />
           </div>
         </div>
         <div className="col-12 mb-12">
           <button
             className="button"
             style={{ width: "100%" }} /*onClick={handleSetSchedule}*/
           >
             Definir
           </button>
         </div>
       </Modal>
      )}
            
    </div>
  );
};

export default MapaHortasAdmin;
