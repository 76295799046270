import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import "./Dashboard.css";
import "../global.css"
import {
  AiOutlineSetting,
  AiOutlineClockCircle,
  AiOutlineCloseCircle,
  AiFillCaretLeft,
  AiFillCaretRight,
  AiOutlinePoweroff,
} from "react-icons/ai";

import {MdOutlineWaterDrop } from "react-icons/md";
import { RxColorWheel } from "react-icons/rx";
import PathComponent from "./PathComponent";
import Left from "../assets/left_map.png";
import Right from "../assets/right_map.png";
import Center from "../assets/center_map.png";
import rega from "../assets/rega.png";
import humidade from "../assets/humidade.png";
import horta2 from "../assets/hortaaaaaaa_print.png";
import temperatura from "../assets/temperatura.png";
import Modal from "react-modal";
import { paths } from "./svgPaths.js";
import { pathsLeft } from "./svgPathsLeft";
import { pathsRight } from "./svgPathsRight";
import { pathsrectangle } from "./svgrectangle";
import { URL } from "../../config.js";
import "../devices/DevicesPage.css"
import "../devices/TestDevice.css"

const MapaHortasAdmin = ({ selectedGarden, selectedMap, handleGarden, handleMap }) => {
  const [isLoading, setIsloading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedPathIndex, setSelectedPathIndex] = useState(null);
  const [hoveredPath, setHoveredPath] = useState(null);
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [horta, setHorta] = useState("");
  const [deviceIds, setDeviceIds] = useState([{}]);
  const [sectionValues, setSectionValues] = useState([]);
  const [conversionTable, setConversionTable] = useState({});
  const [usedDeviceIds, setUsedDeviceIds] = useState([]);
  const [deviceSectionsMap, setDeviceSectionsMap] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  const token = localStorage.getItem("token");  

  const polygonStyles = {
    fill: "#343a40", // Apply the desired fill color here
    stroke: "none", // Remove stroke
  };

  const handleMapChange = (map) => {
    handleMap(map);
  };

  const handleSectionClick = (index) => {
    setSelectedSection(index);
  };
  

  const handleGardenChange = (map) => {
    handleGarden(map);
  };

  const handleSimulatedMouseEnter = (index) => {
    setHoveredPath(index);
  };

  const fetchUsedDeviceIds = async () => {
    try {
      const response = await fetch(`${URL}/nature-garden-api/devices/used-devices`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json", Authorization: token
          },
        }
      );
      const data = await response.json();
      console.log(data);
      setUsedDeviceIds(data);
    } catch (error) {
      console.error("Error fetching used devices:", error);
    }
  };

  // const fetchUpdatedSectionValue = async (sectionIndex) => {
  //   try {
  //     const response = await fetch(
  //       `${URL}/nature-garden-api/sections/${sectionIndex}`
  //     );
  //     const data = await response.json();

  //     // Update the section value in the conversion table
  //     setConversionTable((prevConversionTable) => ({
  //       ...prevConversionTable,
  //       [sectionIndex.toString()]: data.value,
  //     }));
  //   } catch (error) {
  //     console.error("Error fetching updated section value:", error);
  //   }
  // };

  const fetchSectionValues = async () => {
    try {
      const response = await fetch(`${URL}/nature-garden-api/sections`,{
        method: "GET",
        headers: {
          "Content-Type": "application/json", Authorization: token
        },
      });
      const data = await response.json();
      //console.log(data)
      // Create a conversion table (object) to map section indices to their values
      const conversionTable = {};
      data.forEach((section) => {
        conversionTable[section.SectionIndex] = section.SectionValue;
      });
      const deviceSectionsMap = {};
      data.forEach((section) => {
        if (!deviceSectionsMap[section.DeviceID]) {
          deviceSectionsMap[section.DeviceID] = [];
        }
        deviceSectionsMap[section.DeviceID].push(section.SectionValue);
      });

      //console.log(conversionTable)
      // Set the section values and the conversion table states
      setSectionValues(data);
      setDeviceSectionsMap(deviceSectionsMap);
      setConversionTable(conversionTable);
      
      setIsloading(false);
    } catch (error) {
      console.error("Error fetching section values:", error);
    }
  };

  useEffect(() => {
    if (isLoading) {
      fetchSectionValues();
      fetchDeviceIds();
      fetchUsedDeviceIds();
    }
  }, [conversionTable]);

  const fetchDeviceIds = async () => {
    try {
      const response = await fetch(`${URL}/nature-garden-api/devices`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json", Authorization: token
          },
        }
      );
      const data = await response.json();
      setDeviceIds(data);
    } catch (error) {
      console.error("Error fetching device IDs:", error);
    }
  };

  const getSectionsForDevice = (device_id) => {
    const sections = deviceSectionsMap[device_id] || [];
    return sections.length > 0 ? sections.join(", ") : "None";
  };

  const handlePathHover = (index) => {
    setHoveredPath(index);
  };

  const handlePathLeave = () => {
    setHoveredPath(null);
  };

  const handleOpenModal = (index) => {
    // Clear input values here
    setHorta(""); // Assuming horta is the state for one of your input fields
    setSelectedDeviceId(""); // Assuming selectedDeviceId is the state for your device selection

    setShowModal(true);
    setSelectedPathIndex(index);
    setSelectedSection(index);
    setHorta(conversionTable[index] || "");
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setHorta(null);
    setDeviceIds(null);
    setSelectedPathIndex(null);
    setSelectedSection(null); 
  };

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  const handleHortaChange = (event) => {
    setHorta(event.target.value);
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${URL}/nature-garden-api/sections`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: token
        },
        body: JSON.stringify({
          sectionIndex: selectedPathIndex,
          value: horta,
          deviceId: selectedDeviceId,
        }),
      });

      if (response.ok) {
        // Section data submitted successfully, perform any necessary actions
        console.log("Section data submitted successfully");
        fetchUsedDeviceIds();
        setShowModal(false);
      } else {
        console.error("Error submitting section data");
      }
    } catch (error) {
      console.error("Error submitting section data:", error);
    }
  };

  const handleSubmitHorta = async () => {
    try {
      const response = await fetch(
        `${URL}/api-nature-garden/sections/${selectedPathIndex}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: token
          },
          body: JSON.stringify({
            value: horta,
          }),
        }
      );

      if (response.ok) {
        // Update the section value in the conversionTable
        setConversionTable((prevConversionTable) => ({
          ...prevConversionTable,
          [selectedPathIndex.toString()]: horta,
        }));
        fetchSectionValues();

        // Close the modal
        setShowModal(false);

        console.log("Section data submitted successfully");
      } else {
        console.error("Error submitting section data");
      }
    } catch (error) {
      console.error("Error submitting section data:", error);
    }
  };
  const rotationAngle = 40; // Rotation angle in degrees
  const leftMargin = 5000; // Left margin in pixels
  return (
    <>
      <div className="dashboard-container">
        <div className="dashboard-column">
          <div className="left-card">
          <div style={{ alignItems: "center", marginLeft: "2.5rem"}}>
            <MdOutlineWaterDrop  size={100} color="#59817f" />
            <span className="water-clock"><AiOutlineClockCircle size={30} color="#59817f" /></span>
          </div>
          <div className="input-group">
            <label>Abrir válvula: </label>
            <input className="input-item" type="datetime-local" />

            <label>Duração (min):</label>
            <input className="input-item" type="number" />
          </div>
       
        <button className="button" /*onClick={handleSetSchedule}*/>
          Definir
        </button>
        </div>
      
        </div>

        <div className="dashboard-column">
          {selectedGarden === "F" ? (
            <svg
           
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              width="100%"
              height="100%"
              style={{
                shapeRendering: "geometricPrecision",
                textRendering: "geometricPrecision",
                imageRendering: "optimizeQuality",
                fillRule: "evenodd",
                clipRule: "evenodd",
              }}
              viewBox="0 0 12000 12000"
            >
              <circle
                cx="6000"
                cy="6000"
                r="5950"
                fill="#7FA3A1"
                filter="url(#shadow)" // Apply the shadow filter to the circle
              />

              {selectedMap === "L" && (
                <g
                  id="Layer_x0020_L"
                  transform={`translate(400, 400) scale(1.1, 1.1)`}
                >
                  {pathsLeft?.map((path, index) => (
                    <PathComponent
                      key={index + 65}
                      path={path}
                      index={index + 65}
                      hoveredPath={hoveredPath}
                      handlePathHover={handlePathHover}
                      handlePathLeave={handlePathLeave}
                      handleOpenModal={handleOpenModal}
                      sectionValues={sectionValues}
                      handleSectionClick={handleSectionClick}
                      isSelected={selectedPathIndex === index}
                      section={ showModal && selectedSection}
                    />
                  ))}
                </g>
              )}

              {selectedMap === "C" && (
                <g id="Layer_x0020_C">
                  {paths?.map((path, index) => (
                    <PathComponent
                      key={index + 1}
                      path={path}
                      index={index + 1}
                      hoveredPath={hoveredPath}
                      handlePathHover={handlePathHover}
                      handlePathLeave={handlePathLeave}
                      handleOpenModal={handleOpenModal}
                      sectionValues={sectionValues}
                      handleSectionClick={handleSectionClick}
                      isSelected={selectedPathIndex === index}
                      section={showModal && selectedSection}
                    />
                  ))}
                </g>
              )}

              {selectedMap === "R" && (
                <g
                  id="Layer_x0020_R"
                  transform="translate(3800, 905) scale(1.4, 1.4)"
                >
                  {pathsRight?.map((path, index) => (
                    <PathComponent
                      key={index + 80}
                      path={path}
                      index={index + 80}
                      hoveredPath={hoveredPath}
                      handlePathHover={handlePathHover}
                      handlePathLeave={handlePathLeave}
                      handleOpenModal={handleOpenModal}
                      sectionValues={sectionValues}
                      handleSectionClick={handleSectionClick}
                      isSelected={selectedPathIndex === index}
                      section={ showModal && selectedSection}
                    />
                  ))}
                </g>
              )}
            </svg>
          ) : (
            <div className="col-12" style={{height: "100%", width: "100%", zIndex: 0}}>
              <svg
              
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width="100%"
                height="100%"
                style={{
                  shapeRendering: "geometricPrecision",
                  textRendering: "geometricPrecision",
                  imageRendering: "optimizeQuality",
                  fillRule: "evenodd",
                  clipRule: "evenodd",
                  // marginLeft: "15%",
                  //zIndex: 1,
                }}
                viewBox={`0 0 12000 12000`}
              >
                <circle
                  cx="6000"
                  cy="6000"
                  r="5950"
                  fill="#7da4a3"
                  filter="url(#shadow)"
                />
                <g
                  id="Layer_x0020_1"
                  style={{ border: "100px solid #7da4a3" }}
                  transform={`translate(${leftMargin}, 0) scale(0.65, 0.65) rotate(${rotationAngle})`}
                >
                  {pathsrectangle.map((pathData, index) => (
                    <PathComponent
                      key={index + 100}
                      path={pathData}
                      index={index + 100}
                      hoveredPath={hoveredPath}
                      handlePathHover={handlePathHover}
                      handlePathLeave={handlePathLeave}
                      handleOpenModal={handleOpenModal}
                      sectionValues={sectionValues}
                      handleSectionClick={handleSectionClick}
                      isSelected={selectedPathIndex === index}
                      section={ showModal && selectedSection}
                    />
                  ))}
                </g>
              </svg>
            </div>
          )}
        </div>

     

        {showModal && (
          <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            centered
            className="custom-modal"
            ariaHideApp={false}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span className="span-index">
                {"Lote: "}
                {conversionTable[selectedPathIndex] || "none"}
              </span>
                {selectedPathIndex}
              <AiOutlineCloseCircle
              className="modal-close-btn"
                size={30}
                color="white"
                onClick={() => setShowModal(false)}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="col-12" style={{ marginTop: "1rem" }}>
              <div className="mb-4" style={{ justifyContent: "center", alignItems: "center", textAlign: "center" }}>
                <AiOutlineSetting size={100} color="#59817f" />
              </div>
              <div className="input-group">
                {/* <label>Secção da horta : {selectedPathIndex}</label> */}
                <select
                  className="input-item"
                  value={selectedDeviceId}
                  onChange={handleDeviceChange}
                >
                  <option value="" style={{ textAlign: "left" }}>
                    Atribuir dispositivo...
                  </option>
                  {deviceIds?.map(({ DeviceDetails, DeviceID}) => (
                    <option
                      style={{ textAlign: "left" }}
                      key={DeviceID}
                      value={DeviceID}
                      disabled={getSectionsForDevice(DeviceID).split(",").length>=2}
                    >
                      {`${DeviceDetails} (${getSectionsForDevice(DeviceID)})`}
                    </option>
                  ))}
                </select>
              </div>
              <button className="col-12 button" onClick={handleSubmit}>
                Atribuir dispositivo
              </button>
              <br />
              <div
                className="col-12"
                style={{
                  margin: "2rem 0rem",
                  borderBottom: "1px solid lightgray",
                }}
              ></div>
              <div className="input-group">
                <label>Definir n.º da Horta:</label>
                <input
                  className="input-item"
                  type="number"
                  value={horta}
                  onChange={handleHortaChange}
                />
              </div>
              <button className="col-12 button" onClick={handleSubmitHorta}>
                Definir
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default MapaHortasAdmin;
