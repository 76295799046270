export const pathsrectangle     =[
    { d :"M3724.12 7905.03L2191.95 7905.03L2034.27 9274.65L3578.08 9274.65z"  },
    { d :"M10033.02 7803.43l1138.43 0c58.76,-946.95 117.52,-1893.89 176.28,-2840.84l-1204.25 0c-36.82,946.95 -73.64,1893.89 -110.46,2840.84z"  },
    { d :"M11273.25 7803.43l1075.05 0c186.1,-946.95 372.21,-1893.89 558.32,-2840.84l-1457.09 0c-58.76,946.95 -117.52,1893.89 -176.28,2840.84z"  },
    { d :"M9915.09 10836.2l1837.17 0c192.02,-977.06 384.05,-1954.11 576.07,-2931.17l-1061.38 0 -0.19 3.05 -49.17 -3.05 -1188.52 0c-37.99,977.06 -75.99,1954.11 -113.98,2931.17z"  },
    { d :"M4157.94 3836.39l-109.25 1024.6c-1075.03,0 -2150.06,0 -3225.09,0l48.24 -419.33c1095.37,-201.76 2190.73,-403.52 3286.1,-605.27z"  },
    { d :"M13849.52 164.87L12926.58 4860.99L11455.84 4860.99L11691.73 1059.42z"  },
    { d :"M9813.4 10836.2L8438.61 10836.2L8479.13 9376.25L9870.17 9376.25z"  },
    { d :"M8336.97 10836.2L6805.81 10836.2L6897.74 9376.25L8377.5 9376.25z"  },
    { d :"M6704.01 10836.2L5155.63 10836.2L5273.36 9376.25L6795.94 9376.25z"  },
    { d :"M5053.7 10836.2L3513.75 10836.2L3669.42 9376.25L5171.43 9376.25z"  },
    { d :"M3411.58 10836.2L1854.49 10836.2L2022.57 9376.25L3567.25 9376.25z"  },
    { d :"M1752.21 10836.2L136.23 10836.2L304.18 9376.25L1920.3 9376.25z"  },
    { d :"M315.87 9274.65L473.42 7905.03L2089.68 7905.03L1931.99 9274.65z"  },
    { d :"M485.11 7803.43L642.67 6433.81L2259.06 6433.81L2101.37 7803.43z"  },
    { d :"M654.35 6332.21L811.91 4962.59L2428.44 4962.59L2270.75 6332.21z"  },
    { d :"M11587.24 1102.74L10272.38 1647.84L10147.43 4860.99L11354.04 4860.99z"  },
    { d :"M4262.17 3817.19L4150.87 4860.99L5535.52 4860.99L5640.16 3563.38z"  },
    { d :"M5743.85 3541.49L5637.45 4860.99L7080.25 4860.99L7186.97 3166.01z"  },
    { d :"M7290.94 3131.59L7182.05 4860.99L8502.82 4860.99L8568.5 2494.77z"  },
    { d :"M8671.56 2443.4L8604.46 4860.99L10045.75 4860.99L10168.78 1697.09z"  },
    { d :"M10041.8 4962.59L8601.64 4962.59L8563.63 6332.21L9988.54 6332.21z"  },
    { d :"M9984.59 6433.81L8560.81 6433.81L8522.79 7803.43L9931.33 7803.43z"  },
    { d :"M9927.38 7905.03L8519.97 7905.03L8481.95 9274.65L9874.13 9274.65z"  },
    { d :"M8500 4962.59L7175.65 4962.59L7089.41 6332.21L8461.99 6332.21z"  },
    { d :"M8459.17 6433.81L7083.01 6433.81L6996.77 7803.43L8421.15 7803.43z"  },
    { d :"M8418.33 7905.03L6990.38 7905.03L6904.14 9274.65L8380.32 9274.65z"  },
    { d :"M7073.85 4962.59L5629.26 4962.59L5518.82 6332.21L6987.61 6332.21z"  },
    { d :"M6981.21 6433.81L5510.63 6433.81L5400.18 7803.43L6894.97 7803.43z"  },
    { d :"M6888.58 7905.03L5391.99 7905.03L5281.55 9274.65L6802.34 9274.65z"  },
    { d :"M5527.33 4962.59L4140.04 4962.59L3994 6332.21L5416.89 6332.21z"  },
    { d :"M5408.7 6433.81L3983.17 6433.81L3837.13 7803.43L5298.26 7803.43z"  },
    { d :"M5290.06 7905.03L3826.3 7905.03L3680.26 9274.65L5179.62 9274.65z"  },
    { d :"M4037.86 4962.59L2530.72 4962.59L2373.03 6332.21L3891.82 6332.21z"  },
    { d :"M3880.99 6433.81L2361.34 6433.81L2203.65 7803.43L3734.95 7803.43z"  },
];