import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./App.scss";
import "./global.css"
import Navbar from "./shared/Navbar";
import Footer from "./shared/Footer";
import { useTranslation } from "react-i18next";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Dashboard from "./dashboard/Dashboard";
import Administration from "./dashboard/Administration";
import AppRoutes from "./AppRoutes";
import Login from "./Login";
import "./TabStyles.css";
import "./dashboard/dashboard2.css";
import "react-datepicker/dist/react-datepicker.css";
import MapaHortasAdmin from "./dashboard/HortaAdmin";
import "./App.scss"

const App = () => {
  const [activeTab, setActiveTab] = useState("horta_mandala");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();
  const { i18n } = useTranslation();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const role = localStorage.getItem("role");
    if (isLoggedIn === "true") {
      setIsLoggedIn(true);
   
    }
    handleRouteChange();
    // Listen to changes to the route
    const unlisten = history.listen((location, action) => {
      handleRouteChange();
    });
    return () => {
      unlisten();
    };
  }, []);

  const handleRouteChange = () => {
    const body = document.querySelector("body");
    if (history.location.pathname === "/layout/RtlLayout") {
      body.classList.add("rtl");
      i18n.changeLanguage("ar");
    } else {
      body.classList.remove("rtl");
      i18n.changeLanguage("en");
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
    const role = localStorage.getItem("role");
    console.log(role);
    if (role === "superadmin") {
      setActiveTab("admin");
    }
    history.push("/");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
    history.push("/login");
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  if (!isLoggedIn) {
    return <Login onLogin={handleLogin} />;
  }

  return (
    <div style={{ background: "#F5F5EB", minHeight: "100vh" }}>
      <Navbar />
      <div style={{ background: "#F5F5EB" }}>
        <div className="main-container" style={{ background: "#F5F5EB" }}>
          {/* <Tabs
              activeKey={this.state.activeTab}
              onSelect={(tab) => this.handleTabChange(tab)}
              id="dashboard-tabs"
            >
              <Tab eventKey="Hortas" title="Hortas">
                 <Dashboard /> 
              </Tab>
              <Tab eventKey="Agendamento" title="Agendamento">
                <Administration />
              </Tab>
              {localStorage.getItem("role") === "superadmin" ? 
              <Tab eventKey="admin" title="Admin Settings">
                <MapaHortasAdmin />
              </Tab> : ""}
              <Tab eventKey="Agrupar" title="Agrupar">
                {/* <Dashboard /> 
              </Tab>
            </Tabs> */}
          <AppRoutes />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;

