import React, {useState} from 'react';
import { Route, Redirect } from 'react-router-dom';

function ProtectedRoute({ component: Component, allowedRoles, ...rest }) {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("isLoggedIn"));
  const [role, setRole] = useState(localStorage.getItem("role"));

  console.log(localStorage.getItem("token"));
  return (
    <Route
      {...rest}
      render={props => {
        // Check if the user is not authenticated
        if (!isLoggedIn) {
          return <Redirect to="/login" />;
        }
        // Check if the user is authenticated but does not have the required role
        else if (isLoggedIn && allowedRoles==="superadmin" && role!== "superadmin") {
          return <Redirect to="/forbidden" />;
        }
        // User is authenticated and has the required role
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
