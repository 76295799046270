import React, { useRef, useEffect, useState } from "react";

const PathComponent = ({
    path,
    index,
    hoveredPath,
    handlePathHover,
    handlePathLeave,
    handleOpenModal,
    sectionValues,
    section,
    handleSectionClick

}) => {
    const pathRef = useRef(null);
    const [textPosition, setTextPosition] = useState({ textX: 0, textY: 0 });
   
    useEffect(() => {
        const calculateTextPosition = () => {
            if (pathRef.current) {
                const bbox = pathRef.current.getBBox();
                return {
                    textX: bbox.x + bbox.width / 2,
                    textY: bbox.y + bbox.height / 2,
                };
            }
            return { textX: 0, textY: 0 };
        };
        
        const newTextPosition = calculateTextPosition();
        setTextPosition(newTextPosition);
    }, [path.d]); // Recalculate when path changes

    // Find the section value based on the index
    const sectionValue = sectionValues?.find(
        (section) => section.SectionIndex === index
    );
    

   // console.log(sectionValue);

      const handleOnClick = () => {
        handleOpenModal(index);
        handleSectionClick(index);
        //setSelectedSection(selectedSection === index ? null: index); 
    };

    const animationDelay = `${index * 0.005}s`;
  
    return (
        <g
            // style={{
            //     animation: `pulse 1s ${animationDelay}`,
            // }}
        >
            {/* <style>
                {`
                    @keyframes pulse {
                        0%, 100% {
                            transform: scale(1);
                        }
                        50% {
                            transform: scale(1.05);
                        }
                    }
                `}
            </style> */}
           <path
            d={path.d}
            ref={pathRef}
            onMouseEnter={() => handlePathHover(index)}
            onMouseLeave={handlePathLeave}
            onClick={handleOnClick}
            style={{
                fill: section === index ? "orange" : (hoveredPath === index ? "var(--select-color)" : "white"),      transition: "fill 0.3s ease-in-out",
                cursor: "pointer",
            }}
            />

            <text
                x={textPosition.textX}
                y={textPosition.textY}
                textAnchor="middle"
                alignmentBaseline="middle"
                fontSize="298"
                fill={sectionValue ? "#59817f": "#EFEFEB"}
                style={{ cursor: "pointer" }}
            >
                {sectionValue ? sectionValue.SectionValue : "-"}
            </text>
        </g>
    );
};

export default PathComponent;
