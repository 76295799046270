import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import Spinner from "../app/shared/Spinner";

const Dashboard = lazy(() => import("./dashboard/Dashboard"));
const Dashboard2 = lazy(() => import("./dashboard/dashboard2"));
const Administration = lazy(() => import("./dashboard/Administration"));
const Register1 = lazy(() => import("./user-pages/Register"));
const Mdi = lazy(() => import("./icons/Mdi"));
const Error404 = lazy(() => import("./error-pages/Error404"));
const Error500 = lazy(() => import("./error-pages/Error500"));


const Login = lazy(() => import("./Login"));
const HomePage = lazy(() => import("./home/HomePage"));
const DevicesPage = lazy(()=> import("./devices/DevicesPage"));
const HortasPage = lazy(()=> import("./hortas/HortasPage"));
const UsersPage = lazy(()=> import("./users/UsersPage"));
const SchedulePage  = lazy(()=> import("./schedule/SchedulePage"));
const LogPage = lazy(()=> import("./logs/LogPage"));


class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <ProtectedRoute exact path="/" component={HomePage} allowedRoles={["superadmin", "admin"]} />
          <ProtectedRoute exact path="/devices" component={DevicesPage} allowedRoles={"superadmin"} />
          <ProtectedRoute exact path="/schedule" component={SchedulePage} allowedRoles={["superadmin", "admin"]}/>
          <ProtectedRoute exact path="/users" component={UsersPage} allowedRoles={["superadmin", "admin"]}/>
          <ProtectedRoute exact path="/hortas" component={HortasPage} allowedRoles={["superadmin", "admin"]}/>
          <ProtectedRoute exact path="/log-system" component={LogPage} allowedRoles={["superadmin", "admin"]}/>
          
          
          
          {/* ROTAS EM STANDBY*/}
          {/* <Route path="/dashboard" component={Dashboard2} />
          <Route path="/dashboard2" component={Dashboard2} />
          <Route exact path="/administration" component={Administration} />
          
          <Route path="/user-pages/register-1" component={Register1} />

          <Route path="/error-pages/error-404" component={Error404} />
          <Route path="/error-pages/error-500" component={Error500} /> */}

          <Redirect to="/" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
