export const pathsLeft  = [
   { d :"M5192.49 913.06l-855.95 710.51 777.39 330.86c74.82,-235.04 228.88,-600.85 330.83,-822.49 119.29,-242.95 254.44,-476.7 404.11,-699.9l124.65 -203.92 -781.03 684.94z" },
   { d :"M4048.71 2572.19c-5.18,29.26 -10.17,58.59 -14.97,87.98l-0.03 0.17c-41.77,255.47 -69.55,515.65 -82.47,779.73l889.69 0c11.73,-208.93 34.07,-414.99 66.56,-617.69 39.96,-220.62 112.41,-556.87 175.7,-768.39l-821.02 -353.08c-70.98,225 -157.64,606.14 -211.2,871.88l-2.26 -0.6z" },
   { d :"M3983.17 4532.29c2.27,21.09 4.64,42.15 7.11,63.18l0 0.04c21.28,181.73 49.58,361.3 84.66,538.44l869.51 -183.26c-33.49,-170.55 -59.68,-343.7 -78.41,-519.11 -9.32,-136.58 -34.78,-539.01 -34.78,-648.85 0,-109.84 1.61,-161.1 4.76,-241.06l-889.01 0c-2.74,80.02 -4.14,91.61 -4.14,241.06 0,135.86 22.81,520.29 41.84,749.15l-1.54 0.41z" },
   { d :"M3429.29 2406.22l-1079.11 913.84c-2.14,39.93 -4.03,79.93 -5.64,120.01l1555.86 0c13.8,-283.66 44.62,-562.85 91.4,-836.59 45.66,-236.84 129.62,-614.13 198.55,-841.75l-761.06 644.49z" },
   { d :"M2341.04 3541.67c-2.29,80.08 -3.48,160.43 -3.48,241.06 0,223.52 8.63,445.03 25.55,664.21l1550.5 -113.86c-14.24,-181.58 -21.54,-365.11 -21.54,-550.35 0,-80.68 1.39,-161.05 4.12,-241.06l-1555.15 0z" },
   { d :"M2208.48 3440.07l-1153.94 977.2c16.63,260.61 68.02,670.32 104.54,925.01l1212.56 -196.68c-44.82,-354.98 -96.68,-1138.97 -96.68,-1362.87 0,-223.9 5.92,-290.73 8.23,-370.81l-0.01 0 1.64 -36.5 -76.34 64.65z" },
   { d :"M1172.11 5431.63c77.21,501.25 169.66,849.95 319.71,1323.17l1770.86 1027.09c-227.69,-431.39 -419.74,-884.49 -572.22,-1355.39l-31.42 -99.17c-136.25,-439 -182.33,-641.39 -263.35,-1103.31l-1223.58 207.61z" },
   { d :"M2417.21 4951.89l-13.34 -101.61c-13.31,-106.44 -24.66,-213.5 -34.01,-321.13l1549.83 -123.44c1.11,12.5 2.25,25 3.43,37.48l10.41 102.4c22.32,204.56 53.46,406.47 93.08,605.33l-1518.49 335.81c-35.81,-176.37 -66.17,-354.7 -90.91,-534.84z" },
   { d :"M2714.33 6295.41l31.29 99.24c15.44,47.98 31.3,95.77 47.57,143.37l1455.31 -550.63c-43.08,-129.11 -82.47,-259.9 -118.07,-392.25l-26.55 -101.98c-22.64,-89.61 -43.54,-179.92 -62.66,-270.88l-1516.3 345.18c52.61,246.77 115.91,489.59 189.41,727.95z" },
   { d :"M4487.87 6604.7l44.82 98.85c43.38,93.72 52.37,115.05 99.73,206.46l-1426.01 624.59c-145.91,-296.91 -275.06,-603.54 -386.18,-918.67l1451.7 -559.54c64.41,186.49 136.51,369.38 215.94,548.31z" },
   { d :"M3243.34 7608.84c26.76,53.14 54.07,105.97 81.91,158.47l66.22 77.46 1321.92 453.91 1234.65 423.94c-495.59,-492.34 -962.48,-1132.74 -1291.58,-1751.89l-1413.12 638.11z" },
   { d :"M7622.74 8866.88l101.6 63.21c208.09,126.85 424.4,241.53 648.08,342.85l-648.08 -123.22 -101.6 -19.32 -1091.52 -207.53 -179.11 -61.49 -59.53 -20.45 -232.41 -79.8c-124.96,-120.38 -245.37,-245.44 -361,-374.88l673.21 -589.78c65.64,75.82 129.47,147.02 188.79,210.81 319.56,324.8 675.54,613.65 1061.57,859.6z" },
   { d :"M5672.68 6854.87l60.13 99.03c149.56,241.03 377.16,535.06 592.24,787.46l-680.12 583.46c-143.54,-164.54 -279.41,-335.94 -407.08,-513.63l-64.56 -91.35c-170.97,-246.24 -326.3,-504.13 -464.63,-772.24l781.35 -423.47c57.5,112.34 118.44,222.63 182.67,330.74z" },
   { d :"M4176 5568.82l-26.47 -102.02c-20.93,-83.58 -40.33,-167.76 -58.18,-252.52l804.33 -178.27 56.11 -14.49 25.41 84.73c59.77,266.41 206.45,703.37 300.24,955.23 55.25,135.08 115.25,267.71 179.81,397.68l-785.75 415.36c-25.16,-50.22 -49.73,-100.77 -73.69,-151.68l-0.04 -0.1c-9,-19.13 -17.93,-38.29 -26.76,-57.51l1.19 -1.19c-137.65,-319.29 -309.92,-771.9 -396.2,-1095.22z" },
   { d :"M985.9 4475.4l-872.57 738.93 1280.44 1468.74c-216.01,-705.6 -355.09,-1444.73 -407.87,-2207.67z" },
];
